<template>
    <div class="DcoumnetDeleteFileBox">
        <doc-page ref="docPage"></doc-page>
    </div>
</template>
<script>
import titleMixin from '@/mixin/title'
import DocPage from '../Vue/DocPage/index'
export default {
    name: 'DeleteFile',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Doc.1585193743342') }, // '已删除文件'
    data() {
        return {}
    },
    methods: {

    },
    beforeRouteLeave(to, from, next) {
        this.$refs.docPage.beforeLeave(to, from, next)
    },
    watch: {
        $route() {
            if (this.$route.path == '/document/deletefile') {
                this.$refs.docPage.setMenu()
                this.$refs.docPage.reGetList()
            }
        }
    },
    components: {
        'doc-page': DocPage
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
